.selectedNav {
    background: black
}

.customFontStyle {
    fontSize: 11px !important;
    marginLeft: -5px

}

.customAppConfig {
    white-space: nowrap;
}

.customGradient {
    background-image: linear-gradient(to right, #cbb252, #a78234);
}

.open {
    font-weight: bold;
    background-color: #545a60;
}

/*@media (min-width: 767px) {*/
/*.customLeftAlign {*/
/*padding: 0px;*/
/*margin-left: -8px*/
/*}*/
/*}*/

@media (max-width: 768px ) {
    .customLeftAlign {
        /*display: none;*/
        padding: 0px !important;
        margin-left: 0px !important;
    }
}

@media (max-width: 768px ) {
    .scrollbar {
        position: relative !important;

    }
}