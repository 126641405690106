.cardButton1Detail {
    background-color: #e6034b;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 25px !important;
    width: 4cm !important;
}
.customGradient {
    background-image: linear-gradient(to right, #cbb252 , #a78234);
}


div.logoImage{
    /*background-image:url('./../assets/images/logo_single_line_golden.png');*/
    content:url(http://placehold.it/350x150);
}